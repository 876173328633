//
// Google font - Poppins
//

@import url('https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@100;300;400;500;700;900&display=swap');

body {
    font-family: 'Noto Sans KR', sans-serif !important;
    animation: fadeInAnimation ease .5s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}

@keyframes fadeInAnimation {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
     }
}